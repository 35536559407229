import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', () => {
    const isAuthenticated = ref(false);
    const token = ref<string | null>(null);

    async function login(email: string, password: string) {
        try {
            const response = await fetch('/login', {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + btoa(email + ':' + password),
                    'Content-Type': 'application/json',
                }
            });

            // Überprüfe, ob die Antwort einen 403-Fehler zurückgibt
            if (response.status === 403) {
                const errorText = await response.text(); // Fehlertext lesen
                const error = new Error(errorText || 'Forbidden access');
                (error as any).status = 403; // Füge die status-Eigenschaft manuell hinzu
                throw error;
            }

            // Überprüfe, ob die Antwort erfolgreich ist (Status 200)
            if (!response.ok) {
                const errorText = await response.text(); // Fehlerantwort vom Backend lesen
                throw new Error(errorText || 'Invalid login credentials');
            }

            // Hier die JSON-Antwort verarbeiten
            const data = await response.json(); // Antwort wird zu JSON geparst

            isAuthenticated.value = true;
            token.value = btoa(email + ':' + password);
            localStorage.setItem('token', token.value);

            // Rückgabe der Erfolgsmeldung und des Berechtigungslevels an das Frontend
            return {
                message: data.message,
                accessLevel: data.accessLevel,
            };
        } catch (error: any) {
            isAuthenticated.value = false;
            console.error('Login error:', error.message || error);
            throw error; // Fehler weitergeben, damit die Komponente ihn verarbeiten kann
        }
    }

    function logout() {
        isAuthenticated.value = false;
        token.value = null;
        localStorage.removeItem('token');
    }

    return { isAuthenticated, login, logout };
});
