<template>
  <v-app id="inspire">
    <template v-if="!isAuthenticated">
      <router-view/>
    </template>

    <template v-else>
      <v-app-bar app image="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title style="color: white">Project Settings</v-toolbar-title>


        <v-spacer></v-spacer>
        <v-btn color="black" style="background-color: white" @click="logout">Logout</v-btn>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" image="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg" theme="dark"
                           temporary>
        <v-list nav>
          <v-list-item prepend-icon="mdi-open-in-new" title="Projekt öffnen" link to="/openProject"></v-list-item>
          <v-list-item prepend-icon="mdi-plus" title="Neues Projekt" link to="/newProject"></v-list-item>
          <v-list-item prepend-icon="mdi-file-upload" title="Import Betriebsmittel-Liste"
                       @click="triggerFileInput"></v-list-item>
          <br>
          <hr>
          <br>
          <h2>Gebäude</h2>
          <v-list-item v-for="building in buildings" :key="building.id" prepend-icon="mdi-domain"
                       :title="building.bezeichnung" :to="`/gebauede`" @click="onBuildingClick(building)">
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-main id="main">
        <router-view></router-view>
      </v-main>

      <input type="file" ref="fileInput" @change="onFileInputChange" style="display: none;" accept=".csv"/>

      <v-dialog v-model="successDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">Upload Erfolgreich</v-card-title>
          <v-card-text>
            Datei erfolgreich hochgeladen
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text="" @click="successDialog = false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-app>
</template>

<script setup lang="ts">
import {ref, computed, onMounted} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useProjectStore} from '@/stores/projectStores';
import {useBuildingStore} from '@/stores/buildingStores';
import {useBuildingFocusStore} from "@/stores/buildingFocusStores";
import {useAuthStore} from '@/stores/authStore';

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();

const isAuthenticated = computed(() => authStore.isAuthenticated);
const drawer = ref<boolean>(true);
const fileInput = ref<HTMLInputElement | null>(null);
const successDialog = ref<boolean>(false);

const projectStore = useProjectStore();
const buildingStore = useBuildingStore();
const buildingFocusStore = useBuildingFocusStore();

const buildings = computed(() => buildingStore.buildings);

function triggerFileInput() {
  fileInput.value?.click();
}

async function onFileInputChange(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  const files = inputElement.files;

  if (files && files.length > 0) {
    const file = files[0];
    const projectId = projectStore.projectId;

    if (projectId === null) {
      console.error("Kein Projekt ausgewählt");
      return;
    }

    try {
      const response = await uploadFile(file, projectId.toString());
      await fetchAndUpdateBuildings(projectId);
      successDialog.value = true;
    } catch (error) {
      console.error(error);
      alert(`Fehler beim Speichern: ${error}`);
    }
  }
}


async function uploadFile(file: File, projectId: string): Promise<string> {
  const formData = new FormData();
  formData.append('project_number', projectId);
  formData.append('file', file);

  try {
    const response = await fetch('/import', {
      method: 'POST',
      body: formData,
    });

    const responseText = await response.text();
    console.log("Serverantwort:", responseText);

    if (!response.ok) {
      throw new Error(responseText || `Fehler beim Hochladen der Datei: ${response.statusText}`);
    }

    // Optional: JSON parsen, wenn erwartet
    try {
      const result = JSON.parse(responseText);
      console.log("Erfolgreich hochgeladen:", result);
      return responseText;
    } catch {
      return responseText;
    }
  } catch (error) {
    console.error("Fehler beim Senden der Datei an das Backend:", error);
    throw new Error("Ein unbekannter Fehler ist aufgetreten.");
  }
}




async function fetchAndUpdateBuildings(projectId: number) {
  try {
    const response = await fetch(`/buildings?project_id=${projectId}`);
    if (!response.ok) {
      throw new Error('Fehler beim Abrufen der Gebäude');
    }
    const buildings = await response.json();
    buildingStore.setBuildings(buildings);
    console.log("Gebäude erfolgreich aktualisiert:", buildings);
  } catch (error) {
    console.error('Fehler beim Abrufen der Gebäude:', error);
  }
}

onMounted(() => {
  const projectId = projectStore.projectId;
  if (projectId !== null) {
    fetchAndUpdateBuildings(projectId);
  }
});

function onBuildingClick(building: { id: number; bezeichnung: string; buildingAks: string }) {
  buildingFocusStore.setBuilding({
    id: building.id,
    bezeichnung: building.bezeichnung,
    buildingAks: building.buildingAks
  });
}

function logout() {
  authStore.logout();
  router.push('/');
}
</script>

<style>
h1 {
  font-family: "Roboto", serif;
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  color: #00b0ff;
}
</style>
