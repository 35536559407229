import {defineStore} from 'pinia';

export const useEquipmentFocusStore = defineStore('EquipmentFocusStore', {
    state: () => ({
        id: null as number | null,
        bezeichnung: null as string | null,
        equipmentAks: null as string | null,
        equipmentType: null as string | null,
        equipmentManufacturer: null as string | null,
        equipmentStatus: null as number | null,
        systemId: null as number | null,

    }),
    actions: {
        setEquipment(equipment: {
            id: number; bezeichnung: string; equipmentAks: string; equipmentType: string; equipmentManufacturer: string;
            equipmentStatus: number; systemId: number;
        }) {
            this.id = equipment.id;
            this.bezeichnung = equipment.bezeichnung;
            this.equipmentAks = equipment.equipmentAks;
            this.equipmentType = equipment.equipmentType;
            this.equipmentManufacturer = equipment.equipmentManufacturer;
            this.equipmentStatus = equipment.equipmentStatus;
            this.systemId = equipment.systemId;
        },
    },
});

