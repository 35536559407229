import {defineStore} from 'pinia';

export const useBuildingFocusStore = defineStore('BuildingFocusStore', {
    state: () => ({
        buildingId: null as number | null,
        buildingName: null as string | null,
        buildingAks: null as string | null,
    }),
    actions: {
        setBuilding(building: { id: number; bezeichnung: string; buildingAks: string }) {
            this.buildingId = building.id;
            this.buildingName = building.bezeichnung;
            this.buildingAks = building.buildingAks;
        },
    },
});
