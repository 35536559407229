<template>
  <v-app-bar app>
    <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    <v-toolbar-title>{{ buildingNameDisplay }}</v-toolbar-title>
  </v-app-bar>
  <v-navigation-drawer
      v-model="drawer"
      image="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
      theme="dark"
      permanent
  >
    <v-list nav>
      <h2>Anlagen</h2>
      <template v-for="(system, index) in allSystems" :key="index">
        <v-list-item
            prepend-icon="mdi-rhombus-outline"
            @click="onSystemClick(system)"
        >
          <v-list-item-title>{{ system.systemAks }}</v-list-item-title>
          <v-list-item-subtitle>{{ system.bezeichnung }}</v-list-item-subtitle>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>

  <v-container>
    <AnlageView></AnlageView>
  </v-container>
</template>

<script setup lang="ts">
import {ref, computed, onMounted, watch} from "vue";
import {useBuildingFocusStore} from "@/stores/buildingFocusStores";
import {useSystemFocusStore} from "@/stores/systemFocusStores";
import AnlageView from "@/routes/AnlageView.vue";

interface SystemItem {
  id: number;
  systemAks: string;
  bezeichnung: string;
  gebaeude_id: number;
}

const drawer = ref<boolean>(true);
const allSystems = ref<SystemItem[]>([]);
const buildingFocusStore = useBuildingFocusStore();
const systemFocusStore = useSystemFocusStore();


const buildingNameDisplay = computed(() => {
  return buildingFocusStore.buildingName ? `Gebäude: ${buildingFocusStore.buildingName}` : 'Gebäude: Unbekannt';
});

async function loadAllSystems(buildingId: number) {
  try {
    const response = await fetch(`/systems?building_id=${buildingId}`);
    if (!response.ok) {
      throw new Error(`Error fetching systems: ${response.statusText}`);
    }
    const data: SystemItem[] = await response.json();
    allSystems.value = data;
  } catch (error) {
    console.error(error);
  }
}

function onSystemClick(system: SystemItem) {
  systemFocusStore.setSystem(system);
  console.log("Ausgewähltes System:", system);
}

onMounted(() => {
  const buildingId = buildingFocusStore.buildingId;
  if (buildingId) {
    loadAllSystems(buildingId);
  }
});


watch(
    () => buildingFocusStore.buildingId,
    (newBuildingId) => {
      if (newBuildingId) {
        loadAllSystems(newBuildingId);
      }
    }
);
</script>
