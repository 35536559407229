import './assets/main.css'
import {createApp} from 'vue'
import App from './App.vue'
import {createPinia} from 'pinia'
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {createWebHashHistory, createRouter} from 'vue-router'

import HomeView from "@/routes/HomeView.vue"
import AboutView from "@/routes/AboutView.vue"
import GebauedeView from "@/routes/GebauedeView.vue"
import AnlageView from "@/routes/AnlageView.vue"
import DeviceView from "@/routes/DeviceView.vue"
import NewProjectView from "@/routes/NewProjectView.vue"
import OpenProjectView from "@/routes/OpenProjectView.vue"
import LoginView from "@/routes/LoginView.vue"

import {useAuthStore} from '@/stores/authStore'

const vuetify = createVuetify({
    components,
    directives,
})

const pinia = createPinia();

const routes = [
    {path: '/', component: LoginView},
    {path: '/home', component: HomeView, meta: {requiresAuth: true}},
    {path: '/about', component: AboutView, meta: {requiresAuth: true}},
    {path: '/gebauede', component: GebauedeView, meta: {requiresAuth: true}},
    {path: '/anlage', component: AnlageView, meta: {requiresAuth: true}},
    {path: '/device', component: DeviceView, meta: {requiresAuth: true}},
    {path: '/newProject', component: NewProjectView, meta: {requiresAuth: true}},
    {path: '/openProject', component: OpenProjectView, meta: {requiresAuth: true}},
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    const isAuthenticated = authStore.isAuthenticated;

    console.log(`Navigation: ${from.fullPath} -> ${to.fullPath}, Authenticated: ${isAuthenticated}`)

    if (to.meta.requiresAuth && !isAuthenticated) {
        next('/');
    } else if (to.path === '/' && isAuthenticated) {
        next('/home');
    } else {
        next();
    }
})

const app = createApp(App)
app.use(pinia)
app.use(router)
app.use(vuetify)
app.mount('#app')
