<template>
  <head>
    <meta charset="UTF-8" />
    <link rel="Logo" href="/intelitec_Circle.jpeg" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>inteliCheck!</title>
  </head>

  <div>
    <v-img class="mx-auto my-6" max-width="228" src="" />

    <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
      <div class="text-subtitle-1 text-medium-emphasis">Account</div>

      <v-text-field
          v-model="email"
          density="compact"
          placeholder="Username"
          prepend-inner-icon="mdi-account"
          variant="outlined"
      ></v-text-field>

      <v-text-field
          v-model="password"
          :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
          :type="visible ? 'text' : 'password'"
          density="compact"
          placeholder="Enter your password"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          @click:append-inner="visible = !visible"
      ></v-text-field>

      <!-- Fehlernachricht anzeigen -->
      <v-alert v-if="errorMessage" type="error" class="mb-4" dense>
        {{ errorMessage }}
      </v-alert>

      <v-card class="mb-12" color="surface-variant" variant="tonal">
        <v-card-text class="text-medium-emphasis text-caption">
          Der Zugang wird nur der Firma intelitec zur Verfügung gestellt.
          Mitarbeiter der Firma intelitec welche noch kein Zugang haben, benötigt
          es ein Account von der intelitec IT.
        </v-card-text>
      </v-card>

      <v-btn class="mb-8" color="blue" size="large" variant="tonal" block @click="login">
        Log In
      </v-btn>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';

const email = ref('');
const password = ref('');
const visible = ref(false);
const errorMessage = ref(''); // Variable zur Speicherung von Fehlermeldungen
const router = useRouter();
const authStore = useAuthStore();

async function login() {
  errorMessage.value = ''; // Fehlermeldung zurücksetzen

  try {
    const response = await authStore.login(email.value, password.value);

    // Wenn die Anmeldung erfolgreich ist, zum Dashboard weiterleiten
    if (response.accessLevel === 'full' || response.accessLevel === 'restricted') {
      router.push('/home');
    }
  } catch (error: any) {
    // Überprüfung auf 403 Fehler
    if (error.status === 403) {
      errorMessage.value = 'You do not have access to this application.';
    } else if (error.response && error.response.text) {
      // Versuche, die Fehlermeldung vom Backend zu lesen
      errorMessage.value = await error.response.text();
    } else if (error instanceof Error && error.message) {
      errorMessage.value = error.message;
    } else {
      errorMessage.value = 'Login failed. Please try again later.';
    }
    console.error('Login failed:', error);
  }
}
</script>
