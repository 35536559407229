import {defineStore} from 'pinia';

export const useProjectStore = defineStore('projectStore', {
    state: () => ({
        projectId: null as number | null,
        projectNumber: null as number | null,
        projectName: null as string | null,
    }),
    actions: {
        setProject(project: { id: number; number: number; name: string }) {
            this.projectId = project.id;
            this.projectNumber = project.number;
            this.projectName = project.name;
        },
    },
});


