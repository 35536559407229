<template>


  <v-sheet class="mx-auto" width="300">
    <v-container>
      <v-form>
        <v-card-title
            class="bg-blue"
            :style="{ width: comboboxWidth }"
        >
          <span class="text-h5">Create New Project</span>
        </v-card-title>
        <br>
        <v-text-field
            v-model="projectName"
            :style="{ width: comboboxWidth }"
            label="Project Name"
        ></v-text-field>

        <v-text-field
            v-model="projectNumber"
            :style="{ width: comboboxWidth }"
            label="Project Number"
        ></v-text-field>

        <v-text-field
            v-model="projectOrt"
            :style="{ width: comboboxWidth }"
            label="Ort"
            required
        ></v-text-field>

        <v-text-field
            v-model="projectPLZ"
            :style="{ width: comboboxWidth }"
            label="PLZ"
            required
        ></v-text-field>

        <v-text-field
            v-model="projectStrasse"
            :style="{ width: comboboxWidth }"
            label="Strasse"
            required
        ></v-text-field>

        <v-text-field
            v-model="projectHausnummer"
            :style="{ width: comboboxWidth }"
            label="Hausnummer"
            required
        ></v-text-field>

        <v-select
            v-model="projectManager"
            :style="{ width: comboboxWidth }"
            :items="ListOfEmployees"
            label="Project Manager"
            item-value="id"
            item-title="name"
            required
        ></v-select>

        <v-btn
            class="mt-2"
            type="submit"
            block
            @click="onButtonSubmitClick"
        >Submit
        </v-btn>

      </v-form>

    </v-container>
  </v-sheet>

</template>

<script setup lang="ts">
import {onBeforeUnmount, onMounted, ref} from 'vue';

const projectName = ref('');
const projectNumber = ref('');
const projectManager = ref('');
const projectAddress = ref('');

const projectOrt = ref('');
const projectPLZ = ref('');
const projectStrasse = ref('');
const projectHausnummer = ref('');


interface Employees {
  id: number;
  name: string;
  funktion_id: number;
}

const ListOfEmployees = ref<Employees[]>([]);

function fetchLoadEmployeesFromDatabase() {
  fetch("/employees")
      .then((response) => response.json())
      .then((payload) => (ListOfEmployees.value = payload));
}


async function onButtonSubmitClick() {

  let status = testInputData()
  if (status) {

    const formData = new FormData();
    formData.append('name', projectName.value);
    formData.append('nummer', projectNumber.value);
    formData.append('projektleiter', projectManager.value);
    formData.append('ort', projectAddress.value);

    try {

      const data = {
        "project": {
          name: projectName.value,
          nummer: projectNumber.value,
          projektleiter: projectManager.value,
        },

        "address": {
          ort: projectOrt.value,
          plz: projectHausnummer.value,
          strasse: projectStrasse.value,
          hausnummer: projectHausnummer.value
        }

      };

      const response = await fetch('/create_project', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {

        throw new Error(`HTTP error! Status: ${response.status}`);
      } else {

        alert("Project successfully created");
      }
    } catch (error) {

      alert(`Error: 404`);
    }
  }
}

function testInputData() {
  let status: boolean
  if (projectName.value.length > 0) {
    if (projectNumber.value.length > 0) {
      if (projectOrt.value.length > 0) {
        if (projectPLZ.value.length > 0) {
          if (projectStrasse.value.length > 0) {
            if (projectHausnummer.value.length > 0) {
              status = true;
            } else {
              status = false;
            }
          } else {
            status = false;
          }
        } else {
          status = false;
        }
      } else {
        status = false;
      }
    } else {
      status = false;
    }
  } else {
    status = false;
  }
  return status;
}


const comboboxWidth = ref<string>('100px');
const updateComboboxWidth = () => {
  comboboxWidth.value = `${window.innerWidth * 0.5}px`;
};

onMounted(() => {
  window.addEventListener('resize', updateComboboxWidth);
  updateComboboxWidth();
  fetchLoadEmployeesFromDatabase();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateComboboxWidth);
});

</script>

<style>

</style>
