import {defineStore} from 'pinia';

export const useSystemFocusStore = defineStore('SystemFocusStore', {
    state: () => ({
        systemId: null as number | null,
        systemName: null as string | null,
        systemAks: null as string | null,
    }),
    actions: {
        setSystem(system: { id: number; bezeichnung: string; systemAks: string }) {
            this.systemId = system.id;
            this.systemName = system.bezeichnung;
            this.systemAks = system.systemAks;
        },
    },
});
