<template>
  <v-navigation-drawer
      image="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
      theme="dark"
      permanent
  >
    <h4>{{ SystemNameDisplay }}</h4>
    <v-flex class="flex-container">
      <v-container class="button-container">
        <v-btn :class="{'active-button': isActive('open')}" @click="toggleButton('open')">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-btn :class="{'active-button': isActive('onDo')}" @click="toggleButton('onDo')">
          <v-icon>mdi-timer-sand-complete</v-icon>
        </v-btn>

        <v-btn :class="{'active-button': isActive('Check')}" @click="toggleButton('Check')">
          <v-icon>mdi-check-circle</v-icon>
        </v-btn>
      </v-container>
    </v-flex>

    <v-list nav title="Betriebsmittel">
      <template v-for="equipment in filteredEquipments" :key="equipment.id">
        <v-list-item
            prepend-icon="mdi-thermostat"
            :title="`${buildingAks} - ${systemAks} - ${equipment.equipmentAks}`"
            :subtitle="equipment.bezeichnung"
            @click="onEquipmentClick(equipment)"
        >
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>

  <v-container>
    <DeviceView></DeviceView>
  </v-container>

</template>

<script setup lang="ts">
import DeviceView from "@/routes/DeviceView.vue";
import {computed, onMounted, ref, watch} from 'vue';
import {useSystemFocusStore} from "@/stores/systemFocusStores";
import {useBuildingFocusStore} from "@/stores/buildingFocusStores";
import {useEquipmentFocusStore} from "@/stores/equipmentFocusStores";

const systemFocusStore = useSystemFocusStore();
const buildingFocusStore = useBuildingFocusStore();
const equipmentFocusStore = useEquipmentFocusStore();

const buildingAks = computed(() => buildingFocusStore.buildingAks);
const systemAks = computed(() => systemFocusStore.systemAks);
const allEquipments = ref<EquipmentItems[]>([]);


interface EquipmentItems {
  id: number;
  bezeichnung: string;
  equipmentAks: string;
  equipmentType: string;
  equipmentManufacturer: string;
  equipmentStatus: number;
  systemId: number;
}

type StatusFilterMap = {
  [key: string]: number;
};

const statusFilterMap: StatusFilterMap = {
  'open': 0,
  'onDo': 1,
  'Check': 2
};


const filteredEquipments = computed(() => {
  console.log("Active Buttons:", activeButtons.value);
  console.log("All Equipments:", allEquipments.value);

  if (activeButtons.value.length === 0) {
    return allEquipments.value;
  }

  return allEquipments.value.filter(equipment => {
    console.log(`Checking equipment ${equipment.equipmentAks} with status ${equipment.equipmentStatus}`);

    return activeButtons.value.some(button => {
      const status = statusFilterMap[button];
      return status !== undefined && equipment.equipmentStatus === status;
    });
  });
});

async function loadAllEquipment(systemId: number) {
  try {
    const response = await fetch(`/equipments?system_id=${systemId}`);
    if (!response.ok) {
      throw new Error(`Error fetching equipments: ${response.statusText}`);
    }
    const data: EquipmentItems[] = await response.json();
    console.log("Data received from backend:", data);
    allEquipments.value = data;
  } catch (error) {
    console.error(error);
  }
}

const SystemNameDisplay = computed(() => {
  return systemFocusStore.systemName ? `Anlage: ${systemFocusStore.systemName}` : 'Anlage: Unbekannt';
});

const activeButtons = ref<string[]>([]);

function isActive(button: string): boolean {
  return activeButtons.value.includes(button);
}

function toggleButton(button: string): void {
  const index = activeButtons.value.indexOf(button);
  const systemid = systemFocusStore.systemId || 0;
  if (index > -1) {
    activeButtons.value.splice(index, 1);
    loadAllEquipment(systemid)
  } else {
    activeButtons.value.push(button);
    loadAllEquipment(systemid)
  }
}

function onEquipmentClick(equipment: EquipmentItems) {
  equipmentFocusStore.setEquipment(equipment);
  console.log("Ausgewähltes Betriebsmittel:", equipment);
}

onMounted(() => {
  const systemId = systemFocusStore.systemId;
  if (systemId) {
    loadAllEquipment(systemId);
  }

});

watch(
    () => systemFocusStore.systemId,
    (newSystemId) => {
      if (newSystemId) {
        loadAllEquipment(newSystemId);
      }
    }
);
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-direction: column;
}

.button-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.active-button {
  background-color: #00c853;
}
</style>
