import {defineStore} from 'pinia';

export const useBuildingStore = defineStore('BuildingStore', {
    state: () => ({
        buildings: [] as Array<{ id: number; bezeichnung: string; buildingAks: string }> | null,
    }),
    actions: {
        setBuildings(buildings: Array<{ id: number; bezeichnung: string; buildingAks: string }>) {
            this.buildings = buildings;
        },
    },
});

