<template>
  <v-app>
    <v-container>
      <v-card-title
          class="bg-blue"
          :style="{ width: comboboxWidth }"
      >
        <span class="text-h5">Load Project</span>
      </v-card-title>
      <br>
      <v-combobox
          v-model="selectedProject"
          label="Project"
          :items="ListOfProjects"
          :style="{ width: comboboxWidth }"
          item-value="nummer"
          item-title="name"
      >
      </v-combobox>

      <v-btn
          class="mt-2"
          block
          @click="onButtonSubmitClick"
      >Load Project
      </v-btn>

      <!-- Ladebalken -->
      <v-progress-linear
          v-if="loading"
          :value="progress"
          height="5"
          color="blue"
          class="mt-3"
      ></v-progress-linear>

    </v-container>
  </v-app>
</template>

<script setup lang="ts">
import {ref, onMounted, onBeforeUnmount} from 'vue';
import {useRouter} from 'vue-router';
import {useProjectStore} from '@/stores/projectStores';
import {useBuildingStore} from '@/stores/buildingStores';

const comboboxWidth = ref<string>('100px');
const selectedProject = ref<any>(null);

interface Project {
  id: number;
  name: string;
  nummer: number;
  projektleiter: number;
  adresse: number;
}

const ListOfProjects = ref<Project[]>([]);
const loading = ref(false);
const progress = ref(0);


const updateComboboxWidth = () => {
  comboboxWidth.value = `${window.innerWidth * 0.5}px`;
};


function fetchLoadProjectsFromDatabase() {
  fetch("/project")
      .then((response) => response.json())
      .then((payload) => (ListOfProjects.value = payload));
}

async function onButtonSubmitClick() {
  const projectStore = useProjectStore();
  const buildingStore = useBuildingStore();
  const router = useRouter();

  if (selectedProject.value) {
    loading.value = true;
    progress.value = 0;

    const interval = setInterval(() => {
      progress.value += 10;
      if (progress.value >= 100) {
        clearInterval(interval);
        loading.value = false;
        router.push('/home');

        alert('Projekt vollständig geladen');
      }
    }, 300);

    projectStore.setProject({
      id: selectedProject.value.id,
      number: selectedProject.value.nummer,
      name: selectedProject.value.name
    });

    try {
      const response = await fetch(`/buildings?project_id=${selectedProject.value.id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch buildings');
      }
      const buildings = await response.json();
      buildingStore.setBuildings(buildings);
    } catch (error) {
      console.error('Error fetching buildings:', error);
    }
  } else {
    console.error('No project selected');
  }
}

onMounted(() => {
  window.addEventListener('resize', updateComboboxWidth);
  updateComboboxWidth();
  fetchLoadProjectsFromDatabase();
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateComboboxWidth);
});
</script>

<style>
.v-progress-linear {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
</style>
