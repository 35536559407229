<template>
  <v-sheet class="mx-auto" width="300">
    <h4>Bezeichnung</h4>
    <v-text-field
        contenteditable="false"
        :style="{ width: comboboxWidth }"
        :value="bezeichnung"
    ></v-text-field>

    <h4>Kennzeichnungs Code</h4>
    <v-text-field
        :value="totalAks"
        contenteditable="false"
        :style="{ width: comboboxWidth }"
    ></v-text-field>

    <h4>Hersteller</h4>
    <v-text-field
        :value="manufacturer"
        contenteditable="false"
        :style="{ width: comboboxWidth }"
    ></v-text-field>

    <h4>Typ</h4>
    <v-text-field
        :value="typ"
        contenteditable="false"
        :style="{ width: comboboxWidth }"
    ></v-text-field>

    <v-expansion-panels v-model="panels" :style="{ width: comboboxWidth }">
      <v-expansion-panel>
        <v-expansion-panel-title class="check-box-panel">Checkpoints</v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="checkbox-container" :style="{ width: comboboxWidth }">
            <v-checkbox
                v-for="checkpoint in checklist"
                :key="checkpoint.id"
                class="checkbox"
                v-model="checkpoint.typ"
                label="Typ"
            ></v-checkbox>
            <v-checkbox
                v-for="checkpoint in checklist"
                :key="checkpoint.id"
                class="checkbox"
                v-model="checkpoint.einbauort"
                label="Einbauort"
            ></v-checkbox>
            <v-checkbox
                v-for="checkpoint in checklist"
                :key="checkpoint.id"
                class="checkbox"
                v-model="checkpoint.beschriftung"
                label="Beschriftung"
            ></v-checkbox>
            <v-checkbox
                v-for="checkpoint in checklist"
                :key="checkpoint.id"
                class="checkbox"
                v-model="checkpoint.funktion"
                label="Funktionalität"
            ></v-checkbox>
            <v-checkbox
                v-for="checkpoint in checklist"
                :key="checkpoint.id"
                class="checkbox"
                v-model="checkpoint.CheckManagementebene"
                label="Managementebene"
            ></v-checkbox>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title class="check-box-panel">Datenpunkte</v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="checkbox-container" :style="{ width: comboboxWidth }">
            <v-checkbox
                v-for="item in datapoints"
                :key="item.id"
                class="check-box-datapoints"
                :label="`${item.aks} - ${item.bezeichnung} - ${item.signal}`"
                v-model="item.check"
            />
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-title class="check-box-panel">Inbetriebnahme Details</v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-textarea
              label="Brandfallfunktionen (Zeiten), Druckabgleiche usw..."
              v-model="inbetriebnahmeDetails"
          ></v-textarea>
        </v-expansion-panel-text>
      </v-expansion-panel>

      <v-expansion-panel v-if="false">
        <v-expansion-panel-title class="check-box-panel">Mehraufwände</v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-textarea
              label="Beschreibung"
              v-model="mehraufwaendeBeschreibung"

          ></v-textarea>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-btn class="mt-2" type="submit" @click="saveButtonOnClick" block>Save</v-btn>
  </v-sheet>
</template>

<script setup lang="ts">
import {computed, ref, onMounted, onBeforeUnmount, watch} from 'vue';
import {useSystemFocusStore} from '@/stores/systemFocusStores';
import {useBuildingFocusStore} from '@/stores/buildingFocusStores';
import {useEquipmentFocusStore} from '@/stores/equipmentFocusStores';


const systemFocusStore = useSystemFocusStore();
const buildingFocusStore = useBuildingFocusStore();
const equipmentFocusStore = useEquipmentFocusStore();


const buildingAks = computed(() => buildingFocusStore.buildingAks);
const systemAks = computed(() => systemFocusStore.systemAks);
const equipmentAks = computed(() => equipmentFocusStore.equipmentAks);
const bezeichnung = computed(() => equipmentFocusStore.bezeichnung);
const manufacturer = computed(() => equipmentFocusStore.equipmentManufacturer);
const typ = computed(() => equipmentFocusStore.equipmentType);


const totalAks = computed(() => `${buildingAks.value}_${systemAks.value}_${equipmentAks.value}`);

const comboboxWidth = ref<string>('100px');
const updateComboboxWidth = () => {
  comboboxWidth.value = `${window.innerWidth * 0.5}px`;
};


interface DatapointsItem {
  id: number;
  bezeichnung: string;
  aks: string;
  signal: string;
  betriebsmittelId: number;
  check: boolean;
}

interface CheckpointsItem {
  id: number;
  typ: boolean;
  beschriftung: boolean;
  einbauort: boolean;
  funktion: boolean;
  CheckAutomationsebene: boolean;
  CheckManagementebene: boolean;
  notiz: string;
}

const datapoints = ref<DatapointsItem[]>([]);
const checklist = ref<CheckpointsItem[]>([]);
const inbetriebnahmeDetails = ref<string>('');
const mehraufwaendeBeschreibung = ref<string>('');
const panels = ref<number[]>([]);


async function loadAllDatapoints(equipmentId: number) {
  try {
    const response = await fetch(`/datapoints?equipment_id=${equipmentId}`);
    if (!response.ok) {
      throw new Error(`Error fetching datapoints: ${response.statusText}`);
    }
    const data: DatapointsItem[] = await response.json();
    datapoints.value = data;
  } catch (error) {
    console.error(error);
  }
}

async function loadChecklist(equipmentId: number) {
  try {
    const response = await fetch(`/checklist?equipment_id=${equipmentId}`);
    if (!response.ok) {
      throw new Error(`Error fetching checklist: ${response.statusText}`);
    }
    const data: CheckpointsItem[] = await response.json();
    checklist.value = data;

    if (data.length > 0) {
      inbetriebnahmeDetails.value = data[0].notiz;
    } else {
      inbetriebnahmeDetails.value = '';
    }
  } catch (error) {
    console.error(error);
  }
}

async function saveChecklist() {
  try {
    const equipmentId = equipmentFocusStore.id;
    if (!equipmentId) {
      throw new Error("Equipment ID is missing");
    }
    const firstItem = checklist.value[0];

    if (!firstItem) {
      throw new Error("No checklist item found");
    }
    const formattedChecklistItem = {
      betriebsmittelId: equipmentId,
      typ: firstItem.typ,
      beschriftung: firstItem.beschriftung,
      einbauort: firstItem.einbauort,
      funktion: firstItem.funktion,
      CheckAutomationsebene: checkDatapoints(datapoints.value),
      CheckManagementebene: firstItem.CheckManagementebene,
      notiz: inbetriebnahmeDetails.value || ""
    };


    console.log('Sending data:', JSON.stringify(formattedChecklistItem));

    const response = await fetch('/checklist', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formattedChecklistItem)
    });


    console.log('Response status:', response.status);


    const contentType = response.headers.get('Content-Type');
    let responseBody;

    if (contentType && contentType.includes('application/json')) {
      responseBody = await response.json();
    } else {
      responseBody = await response.text();
      console.warn('Response is not JSON. Content:', responseBody);
    }

    if (!response.ok) {
      throw new Error(`Error saving checklist: ${response.statusText}`);
    }

    console.log('Response body:', responseBody);

    if (typeof responseBody === 'object') {
      alert(responseBody.message || "Checklist saved successfully");
    } else {
      console.log("Checklist saved successfully");
    }

  } catch (error) {
    console.error('Error details:', error);
    alert(`Error saving checklist: ${error}`);
  }
}

async function saveDatapoints() {
  try {
    const equipmentId = equipmentFocusStore.id;
    if (!equipmentId) {
      throw new Error("Equipment ID is missing");
    }


    const formattedDatapoints = datapoints.value.map(item => ({
      id: item.id,
      aks: item.aks,
      bezeichnung: item.bezeichnung,
      signal: item.signal,
      betriebsmittelId: equipmentId,
      check: item.check
    }));


    console.log('Sending data:', JSON.stringify(formattedDatapoints));

    for (const datapoint of formattedDatapoints) {
      const response = await fetch('/datapoints', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(datapoint)
      });

      console.log('Response status:', response.status);

      const contentType = response.headers.get('Content-Type');
      let responseBody;

      if (contentType && contentType.includes('application/json')) {
        responseBody = await response.json();
      } else {
        responseBody = await response.text();
        console.warn('Response is not JSON. Content:', responseBody);
      }

      if (!response.ok) {
        throw new Error(`Error saving datapoint with ID ${datapoint.id}: ${response.statusText}`);
      }

      console.log('Response body:', responseBody);

      if (typeof responseBody === 'object') {
        console.log("Checklist saved successfully");
        (responseBody.message || "Datapoint saved successfully");
      } else {
        console.log("Checklist saved successfully");
        ("Datapoint saved successfully");
      }
    }

  } catch (error) {
    console.error('Error details:', error);
    alert(`Error saving datapoint: ${error}`);
  }
}

function checkDatapoints(datapoints: DatapointsItem[]): boolean {
  return datapoints.every(item => item.check === true);
}

function saveButtonOnClick() {
  const equipmentId = equipmentFocusStore.id || 0;
  saveChecklist()
      .then(() => saveDatapoints())
      .then(() => {
        panels.value = [];
      })
      .then(() => {
        alert("Parameter wurden gespeichert")
      });

}

onMounted(() => {
  window.addEventListener('resize', updateComboboxWidth);
  updateComboboxWidth();

  const equipmentId = equipmentFocusStore.id;
  if (equipmentId !== null) {
    loadAllDatapoints(equipmentId);
    loadChecklist(equipmentId);
  }
});

watch(equipmentAks, () => {
  const equipmentId = equipmentFocusStore.id;
  if (equipmentId !== null) {
    loadAllDatapoints(equipmentId);
    loadChecklist(equipmentId);
  }
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateComboboxWidth);
});
</script>

<style>
.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.check-box {
  color: black;
  margin-bottom: 0;
  width: 200px;
}

.check-box-panel {
  color: cornflowerblue;
  font-size: 20px;
}

.check-box-datapoints {
  color: black;
  margin-bottom: 0;
  width: 400px;
}

h4 {
  color: cornflowerblue;
  font-size: 20px;
}
</style>
